import React from 'react'
import { graphql, Link } from 'gatsby'
// import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'

import CustomerTemplateLayout from '../components/CustomerStories/CustomerTemplateLayout'
import CustomerRightCol from '../components/CustomerStories/CustomerRightCol'
import CustomerLeftCol from '../components/CustomerStories/CustomerLeftCol'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'



interface Props {
  data: any
  pageContext: any
}
const CustomerStoryTemplate = ({ data, pageContext }: Props) => {
  const { wpTestimonial } = data
  const breadcrumbData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Customer Stories',
      url: '/customer-stories',
    },
    {
      title: wpTestimonial.title,
      url: '/' + wpTestimonial.slug,
    },
  ]
  // const { acfCourse2: courseData2 } = courseData

  const renderedBlocks = LazyBlocksFactory(
    wpTestimonial.lazy_data,
    wpTestimonial.title
  )

  return (
    <Layout>
      <MainGrid noPaddingMobile noPaddingTablet backgroundMobile="white">
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <CustomerTemplateLayout>
        <CustomerLeftCol title={wpTestimonial.title} />
        <CustomerRightCol>
          <h1 style={{ marginBottom: 67 }}>{wpTestimonial.title}</h1>
          {renderedBlocks.map((v) => v)}
          <Link to="/customer-stories">Back to customer stories</Link>
        </CustomerRightCol>
      </CustomerTemplateLayout>
      
    </Layout>
  )
}

export default CustomerStoryTemplate

export const pageQuery = graphql`
  query StoryById($id: String!) {
    wpTestimonial(id: { eq: $id }) {
      title
      slug
      lazy_data
      acfTestimonial {
        description
        logo {
          localFile {
            childImageSharp {
              id
            }
          }
        }
      }
    }
  }
`
